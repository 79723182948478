<template>
<subscription-required/>
    
</template>

<script>

import SubscriptionRequired from '../../components/app/SubscriptionRequired'
export default {
  components: { SubscriptionRequired },
    metaInfo: {
        title: 'Subscription Required'
    },
}
</script>