<template>
        <div class="columns is-desktop">
            <div class="column has-text-centered">
                <div>
                    <img src="/images/veecli-logo.png">
                    <br>
                    <span class="title is-1">VEECLi</span>

                    <hr>

                    <p class="title is-3">{{ serviceName }} Data not Available!</p>
                    <hr>
                    <p class="is-size-5">Please contact our sales team</p>
                    <div class="level">
                        <div class="level-item title is-4">support@veecli.com</div>
                        <div class="level-item title is-4">(484) 4-VEECLi</div>
                        <div class="level-item title is-4">(484) 483-3254</div>
                    </div>
                </div>               
            </div>
        </div>    
</template>

<script>
export default {

    props: {
        serviceName: String
    },

    // data() {
    //     return {
    //         serviceName: ''
    //     }
    // },

    mounted() {
        // this.serviceName = this.$route.query.code
        // console.log("Service", this.serviceName);
    }
}
</script>

<style scoped>
.columns {
    padding: 20px;
}
</style>